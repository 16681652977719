import "./style.css";

import { createApp, type DefineComponent, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import axios from "axios";

const pages = import.meta.glob("./pages/**/*.vue");

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

createInertiaApp({
    resolve: async (name) => {
        return (await pages[`./pages/${name}.vue`]()) as DefineComponent;
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .mount(el);
    },
    progress: {
        color: "#EC4899",
    },
});
